$height: 36px;
$width: 90;
$padding: 2px;
$border: 1px;
$border-radius: 10px;

// tooltip
$tooltip-width: 140px;
$tooltip-background: hsl(0, 0, 40);

// TODO: import me
$selections-background-color: #ffe;

.toggle {
  background: #bbb;
  border: $border solid #bbb;
  border-radius: $border-radius;
  position: relative;
  width: $width;
  height: $height;
  cursor: default;

  transition: 1s cubic-bezier(0.55, 1, 0.68, -0.6);

  &.toggle-status-on {
    background-color: #548c54;
  }

  &.toggle-disabled {
    background-color: hsl(0, 0, 65);
  }

  &:hover {
    cursor: pointer;

    & .disabled-toggle-tooltip-text {
      visibility: visible;
    }
  }

  & .disabled-toggle-tooltip-text {
    visibility: hidden;

    background-color: $tooltip-background;
    font-size: 0.8rem;
    color: #fff;

    text-align: center;
    padding: 5px 7px;
    border-radius: 6px;

    width: $tooltip-width;
    position: absolute;
    z-index: 1;

    top: 5px;
    right: 110%;
    margin-left: -$tooltip-width/2;
  }
}

.toggle-slider {
  border-radius: $border-radius - $padding;
  position: absolute;
  height: $height - 2 * $padding - 2 * $border;
  width: ($width - 4 * $padding - 2 * $border) / 2;
  bottom: $padding;
  left: $padding;
  transition: inherit;

  background-color: $selections-background-color;

  &.toggle-status-on {
    transform: translateX(($width - 2 * $border) / 2);
  }

  &.toggle-disabled {
    color: hsl(0, 0, 25);
  }
}

.toggle-text-off {
  position: absolute;
  bottom: ($height - 2 * $border) / 2;
  left: 0;
  line-height: 0;
  width: ($width - 2 * $border) / 2;
  text-align: center;
  transition: inherit;

  &.toggle-status-on {
    color: white;
  }

  &.toggle-disabled {
    color: hsl(0, 0, 85);
  }
}

.toggle-text-on {
  position: absolute;
  bottom: ($height - 2 * $border) / 2;
  left: ($width - 2 * $border) / 2;
  line-height: 0;
  width: ($width - 2 * $border) / 2;
  text-align: center;
  color: white;
  transition: inherit;

  &.toggle-status-on {
    color: black;
  }
}
