$background-color: hsl(0, 0%, 94%);
$border-color: #ddd;
$selections-background-color: hsl(0, 0%, 100%);
$header-footer-background-color: #194a7e;

// tooltip
$tooltip-width: 120px;
$tooltip-background: #666;

.cookie-consent {
  background-color: $background-color;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 500px;
  z-index: 5;
  filter: drop-shadow(0px 0px 4px hsla(0, 0%, 0%, 0.5%));
}

.cookie-consent-header {
  padding: 10px;
  background-color: $header-footer-background-color;
  color: white;
}

.cookie-consent-message {
  padding: 10px 10px 25px;
}

.cookie-consent-nav {
  display: flex;
  overflow-x: scroll;
}

.cookie-consent-nav > li {
  padding: 0.5rem;
  border: 1px solid $border-color;
  cursor: default;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

.cookie-consent-nav > li.active {
  background-color: $selections-background-color;
  border-top: 4px solid #d69;
  border-bottom: none;
}

.cookie-consent-selection-list {
  height: 150px;
  background-color: $selections-background-color;
  overflow-y: scroll;
}

.cookie-consent-cookie {
  padding: 3px 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
}

.cookie-consent-cookie:last-child {
  border-bottom: none;
}

.cookie-consent-footer {
  color: white;
  background-color: $header-footer-background-color;
  padding: 10px 10px 20px;
}

.cookie-consent-message-buttons {
  padding-top: 10px;
}

.cookie-consent-interact-button {
  cursor: pointer;

  border: none;
  background: none;

  text-decoration: underline;
  font-weight: bold;
  font-size: 1rem;
  color: hsl(0, 0%, 20%);

  position: relative;

  &:hover {
    color: $header-footer-background-color;
  }

  &:disabled {
    cursor: default;
    color: hsl(0, 0, 50);

    &:hover .cookie-consent-interact-button-tooltip-text {
      visibility: visible;
    }
  }
}

.cookie-consent-interact-button-tooltip-text {
  visibility: hidden;

  background-color: $tooltip-background;
  font-weight: normal;
  font-size: 0.8rem;
  color: white;

  text-align: center;
  padding: 5px 7px;
  border-radius: 6px;

  width: $tooltip-width;
  position: absolute;
  z-index: 1;

  left: 110%;
}

.cookie-consent-interact-button-light {
  color: hsl(0, 0, 100);

  &:hover {
    color: hsl(0, 0, 93);
  }
}

.cookie-consent-footer-buttons {
  display: flex;
  justify-content: space-between;

  margin-top: 10px;
}
