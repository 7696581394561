@import "./reset.css";
@import "./type-scale.css";
@import "https://fonts.googleapis.com/css2?family=Merienda:wght@700&family=PT+Serif&display=swap";

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  margin: 0px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  font-family: "PT Serif", serif;
  background-image: url(image.svg);
  background-blend-mode: overlay;
  background-color: rgb(240, 240, 240);
  background-size: 100px 100px;
  padding: 20px 0px 0px 0px;
}

.welcome-line {
  font-family: "Merienda", cursive;
  font-weight: bold;
  padding-bottom: 10px;
  text-align: right;
}

.welcome-line:nth-child(1) {
  color: #d99;
  padding-right: 25px;
}

.welcome-line:nth-child(2) {
  color: #d55;
  padding-right: 160px;
}

.welcome-line:nth-child(3) {
  color: #d22;
  padding-right: 50px;
}

.header-box {
  padding: 10px;
  width: 480px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  background-color: #fff;
}

.content-box {
  background-color: #fff;
  width: 640px;
  margin: auto;
  border: 2px solid #ccc;
}

.nav-and-content {
  display: flex;
}

.nav-bar {
  background: #cc8;
  white-space: nowrap;
  padding-right: 5px;
}

.nav-item {
  font-family: "Merienda", cursive;
  background-color: #ddc;
  margin-top: 10px;
  padding: 7px;
  border-radius: 0px 20px 20px 0px;
  border: 1px solid #ccc;
  border-left: 0px;
}

.header {
  font-family: "Merienda", cursive;
  font-size: 42px;
  text-align: center;
  padding: 10px;
}

.content {
  padding: 15px;
  line-height: normal;
}
